// ********************* MOUNTED ************************** //
const isMobile = window.innerWidth < 1024;
window.localStorage.setItem('v', 'false')

// Expertise tabs. Set "Blockchain" (first) tab by default.
window.addEventListener('DOMContentLoaded', ready => {
    if (ready) {
        if (!isMobile) {
            document.getElementById('blockchain').classList.add('active')
        } else {
            document.getElementById('blockchain_mobile').classList.add('active')
        }
    }
})

// Scroll down button.
let scrollDownButton = document.querySelector('.scroll-down__button')
if (scrollDownButton) {
    let _clicked = false
    scrollDownButton.addEventListener('click', evt => {
        if (!_clicked) {
            _clicked = true
            document.documentElement.scrollBy(0, window.innerHeight - 90)
        }
    })
}